<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

<!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input
          v-model="searchForm.img_title"
          size="small"
          placeholder="文件名"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px"
        />
        <el-cascader v-model="searchForm.class_id"
                     :props="{
              label:'name',value:'id',emitPath:false}"
                     :options="classList" size="small"
                     @change="handleChange"
                     style="margin-right: 10px; margin-left: 10px;"
                     placeholder="班级名称" clearable></el-cascader>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
        <el-button type="primary" size="small" @click="saveInfo()">添加</el-button>

        <div style="display: flex;justify-content: flex-end;flex: 1">
          <el-button size="small" type="primary" plain icon="el-icon-refresh" @click="initClassPhoto">初始化相册</el-button>
        </div>
      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column
        prop="img_title"
        label="文件名"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="class_name"
        label="所属班级"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="cdate"
        label="创建时间"
        min-width="100"
      ></el-table-column>

      <el-table-column prop="img_url" label="图片" min-width="120">
        <template slot-scope="scope">
          <div style="width: 100px; line-height: 50px; overflow: hidden">
            <img :src="scope.row.img_url" style="width: 40px; height: 40px" v-viewer/>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="findOne(scope.row)">编辑</el-button>
          <el-button
            type="text"
            size="mini"
            @click="delectMessage(scope.row)"
            style="margin-left: 10px;"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="编辑班级照片" :visible.sync="dialogVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="文件名" prop="img_title">
          <el-input v-model="ruleForm.img_title"></el-input>
        </el-form-item>
        <el-form-item label="所属班级" prop="class_name">
          <el-cascader ref="cascaderClass"
                       v-model="ruleForm.class_id"
                       :props="{
              label:'name',value:'id',emitPath:false}"
                       :options="classList"
                       @change="handleChange"
                       style="width: 100%"></el-cascader>
        </el-form-item>

        <el-form-item label="图片" prop="img_url">
          <el-row>
            <el-col :span="4"> &nbsp;</el-col>
            <el-col :span="20">
              <el-upload
                action=""
                :http-request="uploadOss"
                :on-success="uploadCover"
                :show-file-list="false"
                name="img_url"
              >
                <div slot="tip" class="el-upload__tip">尺寸750:360</div>
                <img
                  v-if="ruleForm.img_url !== ''"
                  :src="ruleForm.img_url"
                  width="125"
                />
                <el-button v-else size="small" type="primary"
                >点击上传
                </el-button
                >
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
          >保存
          </el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {
      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm:{},
      rules: {
        img_title: [
          {required: true, message: "请填写图片标题!", trigger: "blur"},
        ],
        img_url: [{required: true, message: "请选择图片", trigger: "change"}],
        class_id: [
          {required: true, message: "请选择班级", trigger: "change"},
        ],
      },
      classList: [],
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
    this.getClass()
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getlist() {
      this.$http
        .post("/api/sys_scenery_img_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          type: 1,
          img_title: this.searchForm.img_title,
          class_id: this.searchForm.class_id,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    saveInfo(e) {
      this.ruleForm = {
        id: "",
        class_name: "",
        class_id: "",
        img_title: "",
        img_url: "",
        type: 2,
      };
      this.dialogVisible = true;
    },
    delectMessage(e) {
      this.$confirm('此操作不可恢复，确认删除？',{
        type: 'warning'
      }).then(() => {
        this.$http
          .post("/api/sys_scenery_img_delete", {
            id: e.id,
          })
          .then((res) => {
            this.getlist();
          });
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/api/sys_scenery_img_edit", {
              id: this.ruleForm.id,
              img_title: this.ruleForm.img_title,
              class_name: this.ruleForm.class_name,
              class_id: this.ruleForm.class_id,
              img_url: this.ruleForm.img_url,
              type: 1,
            })
            .then((res) => {
              this.dialogVisible = false;
              this.getlist();
            });
        } else {
          console.log("保存失败!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    uploadCover(e) {
      this.ruleForm.img_url = e.src;
    },
    handleChange(e) {
      let name = this.$refs['cascaderClass'].getCheckedNodes()[0].pathLabels[2]
      this.ruleForm.class_name = name
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }
        traversal(treeData)
        resolve(treeData)
      })
    },
    findOne(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.img_title = e.img_title;
      this.ruleForm.class_name = e.class_name;
      this.ruleForm.class_id = Number(e.class_id);
      this.ruleForm.img_url = e.img_url;
      this.dialogVisible = true;
    },
    getClass() {
      this.$http.post("/api/office_tree_classes", {is_all: 1}).then((res) => {
        this.deleteEmptyGroup(res.data[0].children).then(r => {
          this.classList = r
        })
      });
    },
    initClassPhoto() {
      this.$http.post('/api/initClassImg').then(res =>{
        this.$message.success('初始化中，请稍后刷新页面')
        this.getlist()
      })
    },
  },
};
</script>
